import {
  GetApplicationDocument,
  GetApplicationQuery,
  GetApplicationQueryVariables,
  ProfileType,
  useUpdateProfileMutation,
} from "@/graphql/types";
import { QueryOptions } from "@apollo/client";
import gql from "graphql-tag";

gql`
  mutation updateProfile($input: UpdateProfileInputType!) {
    updateProfile(input: $input) {
      ...ProfileParts
    }
  }
`;

export default function () {
  const mutation = useUpdateProfileMutation({});

  function call(applicationId: string, profile: ProfileType) {
    mutation.mutate(
      {
        input: {
          profileId: profile.profileId,
          name: profile.name,
          description: profile.description ?? "",
        },
      },
      {
        update: (cache, { data: update }) => {
          const parentQuery: QueryOptions<
            GetApplicationQueryVariables,
            GetApplicationQuery
          > = {
            query: GetApplicationDocument,
            variables: {
              applicationId: applicationId,
            },
          };
          const cachedData = cache.readQuery(parentQuery);
          cache.writeQuery({
            ...parentQuery,
            data: {
              application: {
                ...cachedData?.application,
                profiles: cachedData?.application?.profiles?.map((element) =>
                  element?.profileId == update?.updateProfile?.profileId
                    ? { ...update?.updateProfile }
                    : element
                ),
              },
            },
          });
        },
      }
    );
  }

  return {
    ...mutation,
    call,
  };
}
